import { h, render, Component } from 'preact';
import { IntlProvider, MarkupText } from 'preact-i18n';
import { Text } from 'preact-i18n';
import { GetSetAffiliateId, GetSaferShoppingUrl } from '../utils/ApiUtil';
import { Header } from '../common/Header';
import {Footer} from '../common/Footer';
import { TranslationUtil } from '../utils/TranslationUtil';
import { RtlUtil } from '../utils/RtlUtil';
import { ExtensionUtil } from '../utils/ExtensionUtil';
import { DetectBrowser } from '../utils/DetectBrowser';
import {WidgetHelper} from '../widgets/utils/widgetHelper';

import './landing.scss';
import { browseAdClick } from '../utils/SiteAnalytics';

class Lp extends Component {
  constructor(props) {
    super(props);
    const lang = TranslationUtil.GetSupportedLanguage();
    const browserName = DetectBrowser.getBrowserName();
    this.state = {
      affid: props.affid,
      lang: lang,
      isRightToLeft: RtlUtil.GetSetRtl(lang),
      definition: {},
      browserName: browserName ? browserName : "Chrome", // default to chrome
      isExtensionInstalled: false
    }
    this.checkDelta = 400;
    this.isAdUrl = props.isAd;

    TranslationUtil.GetDefinition(lang, "landing").then(resp => {
      this.setState(c => {
        c.definition = resp;
        return c;
      })
    })
    WidgetHelper.SetParam("translang", this.state.lang, false);
    WidgetHelper.SetParam("affid", this.state.affid, false);

    this.benefits = [
      { 
        id: "WebSafety",
        logo: "images/web_safety_logo_icon_text.png",
        buttonText: <MarkupText id="landing.installButton2">Get Browse.live Web Safety</MarkupText>,
        title: <Text id="landing.benefit2Title">The world’s most authentic browsing experience.</Text>,
        description: [<Text id="landing.benefit2Point3">Keep your browsing safe and clean</Text>,
                      <Text id="landing.benefit2Point1">Block ad polluters and advertising fraud</Text>, 
                      <Text id="landing.benefit2Point2">Protect search results from deceptive apps</Text>,
                      <Text id="landing.benefit2Point4">Now Free!</Text>
                    ],
        imageSrc: (this.state.isRightToLeft ? "/images/lp/Browse_Web_Safety_rtl.svg" : "/images/lp/Browse_Web_Safety.svg")
      },
      { 
        id: "True",
        logo: "images/true_premium/True_logo_dark-yellow-green.png",
        buttonText: <MarkupText id="landing.comingSoon">Coming Soon!</MarkupText>,
        title: <Text id="landing.benefit1Title">The world’s first unified anti-malware, web safety, and ad blocking product.</Text>,
        description: [<Text id="landing.benefit1Point1">Real-time protection from malware and unwanted software</Text>, 
                      <Text id="landing.benefit1Point2">Browser protection from ad pollution and deceptive websites</Text>,
                      <Text id="landing.benefit1Point3">Safety Scanner to detect if your system has been mis-configured</Text>
                    ],
        imageSrc: (this.state.isRightToLeft ? "/images/lp/Browse_Search_rtl.svg" : "/images/lp/Browse_Search.svg")
      }
    ];
  }
  componentDidMount() {
    window.scrollTo(0,0);
    document.body.scrollTop = 0;
    this.setInstallationStatus();
  }
  setInstallationStatus(cnt=1) {
    ExtensionUtil.isBrowseInstalled().then(resp => {
      //console.log("resp", resp);
      if(resp !== undefined && resp !== null && resp !== "") {
        //console.log("installation resp", resp);
        //this means we got installation status
        this.setState(c => {
            c.isExtensionInstalled = resp;
            return c;
        });
      } else {
          // trying to get installation status again
        this.tryGetInstallationStatus(cnt);
      }
    },).catch(() => {
      this.tryGetInstallationStatus(cnt);
    })
  }
  tryGetInstallationStatus(cnt=0) {
    if(cnt > 20) {
      return; //almost waited for 8 seconds to find it out
    }
    setTimeout(() => {
      this.setInstallationStatus(cnt+1)
    }, this.checkDelta);
  }
  renderSupportedBrowserText() {
    let browserText = ExtensionUtil.GetBrowserStoreText(this.state.browserName); // default to chrome
    let browserImage = ExtensionUtil.GetBrowserStoreImage(this.state.browserName);
    if (browserText) {
      return(
        <div className='supported-browser-text'>
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" fill="#47BA77" width="35" height="25" viewBox="0 0 24 24">
              <path fill="none" d="M0 0h24v24H0z"/><path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"/>
            </svg>
          </div>
          <div>
            <Text id="landing.installText" fields={{browser: browserText}}>
              Click the button below to install from {browserText}
            </Text>
          </div>
          <div><img src={browserImage} className='browser-logo'/></div>
        </div>
      )
    } else {
      return("");
    }    
  }
  downloadClick(id) {
    const storeLink = ExtensionUtil.GetStoreLink(this.state.browserName, this.state.lang, "browse.live", "organic", "index", id);
    const linkTarget = ExtensionUtil.GetStoreLinkOpenTarget(this.state.browserName);
    browseAdClick("browse.live", "organic", "index");
    window.open(storeLink, linkTarget);
  }

  renderLogo(id, logoUrl) {
    return(
      <div className={"benefit-card-logo logo" + (id == "WebSafety" ? "2" : "1")}>
        <img src={logoUrl} alt={id + " logo"} />
      </div>
    )
  }

  renderDownloadButton(id, buttonText) {
    //let isExtensionInstalled = (DetectBrowser.isBrowserSupported(this.state.browserName) === false) ? true : this.state.isExtensionInstalled;
    if(DetectBrowser.isBrowserSupported(this.state.browserName) === false) {
      return ("")
    } else {
      return (
        <a href="javascript:void(0)" onClick={ () => { if (id == "WebSafety") {this.downloadClick(id)}}}>
          <button className={"download-button ae-title download-product" + (id == "WebSafety" ? "2" : "1")}>
            <div>
              {buttonText}
            </div>
          </button>
        </a>
      )
    }
  }
  renderBenefitsSection() {
    return (
      <div>
        <div className='benefits-section'>
          <div className='flex-row-center'>
            <div className='benefits-wrapper'>
              {this.benefits.map((item) => {
                return (
                  <div className='benefit-card'>
                    <div>
                      {this.renderLogo(item.id, item.logo)}
                      <div className='ae-subheadline benefit-card-title'>{item.title}</div>
                      <div className='download-button-wrap'>
                        {this.renderDownloadButton(item.id, item.buttonText)}
                      </div>
                      <div className='ae-para-large benefit-card-description'>
                        <div>
                          {item.description.map((point) => {
                            return (
                              <div className='benefit-card-description-point'>
                                <div className={"checkmark card" + (item.id == "WebSafety" ? "2" : "1")}>
                                  <div className="checkmark-stem"></div>
                                  <div className="checkmark-kick"></div>
                                </div>
                                <div className='checkmark-text'>{point}</div>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    )
  }
  renderOnlineSafetySection() {
    return (
      <div id="onlineSafetyBlock" className='whyWebSafety-section'>
          <div className="title-margin ae-headline"><Text id="landing.whyWebSafetyTitle">Why online safety is better</Text></div>
          <div className='flex-row-center'>
            <div>
              <img src="/images/home/web_safety_home_benefits.png" alt="web safety benefits" />
              <div className='image-caption'><Text id="landing.whyWebSafetyImageCaption">Online safety is about YOU, not just your machine.</Text></div>
            </div>            
            <div className='container ae-para-huge paragraph-padding'>
              <Text id="landing.whyWebSafetyText">Online safety means going beyond traditional PC protection, which only targets malware on your machine and becomes helpless when it comes to safety on the web. Simple malware protection is not enough to keep you safe when the dangers of the online world come into play. These include exploitation of your personal data, online fraud, scams, and Internet pollution, which comes in the form of ads, pop-ups, and all other pollution that distracts you from your browsing and attempts to trick you or steal your information. The ultimate goal of Browse.live is to give you all-encompassing safety on the web, so that you can browse the web peacefully and without distractions.</Text>
            </div>
          </div>
        </div>
    )
  }
  renderBuiltBySection() {
    return (
      <div id="aboutUsBlock" className='builtbyappesteem-section'>
        <div className="title-margin ae-headline"><Text id="landing.builtbyTitle">Built by AppEsteem Corporation</Text></div>
        <div className='flex-row-center'>
          <div className='container ae-para-huge'>
            <MarkupText id="landing.builtbyText">Browse.live products are created by AppEsteem, an app certification company that is trusted by the world’s leading cybersecurity companies to help protect more than 2 billion people from consumer fraud. Our mission is to bring safe and clean internet experiences to everybody.</MarkupText>
          </div>
          <div className='paragraph-padding'>
            <a href='https://customer.appesteem.com/polluters' target='_blank'>Polluter Program</a>&nbsp;&nbsp;&nbsp;
            <a href='https://customer.appesteem.com/certified' target='_blank'>Certified Apps</a>&nbsp;&nbsp;&nbsp;
            <a href='https://customer.appesteem.com/deceptors' target='_blank'>Deceptors</a>
          </div>
          <div className='appesteem-logo paragraph-padding'>
            <a href='https://appesteem.com/' target='_blank'><img src='/images/AppEsteem_Logo_with_R_web.png' alt="AppEsteem logo" /></a>
            
          </div>
        </div>
      </div>
    )
  }
  renderFAQ() {
    let activeFaq = 0;
    const faq = [
      {question: <Text id="landing.question2">What criteria does Browse.live use to label an ad network or website as a Polluter?</Text>, answer: <MarkupText id="landing.answer2">Browse.live uses AppEsteem’s <a href='https://customer.appesteem.com/home/PolluterIndicators' target='_blank'>polluter indicators list</a> to determine if an ad network or a website is a Polluter.</MarkupText>},
      {question: <Text id="landing.question3">Which browser do I need to use these extensions?</Text>, answer: <Text id="landing.answer3">Browse.live Web Safety supports desktop versions of Chrome, Edge, and Firefox.</Text>},
      {question: <Text id="landing.question4">How does Browse.live Web Safety fight ad pollution?</Text>, answer: <MarkupText id="landing.answer4">Browse.live Web Safety fights ad pollution by blocking all <a href='https://customer.appesteem.com/polluters' target='_blank'>Polluter ads and websites</a> and fading all the remaining web ads that are considered fair to consumers.</MarkupText>},
      {question: <Text id="landing.question5">Does Browse.live Web Safety allow any ads?</Text>, answer: <Text id="landing.answer5">Browse.live Web Safety only allows ads that are considered fair according to AppEsteem’s Polluter Indicators list. These ads are automatically faded across the web. All Polluter ads across the web are automatically blocked.</Text>},
      {question: <Text id="landing.question6">How does the ad fading feature work in Browse.live Web Safety?</Text>, answer: <Text id="landing.answer6">Browse.live Web Safety reduces the intensity of the ads on your web pages so you can focus on the content without getting distracted. You can adjust the intensity of the fading, and if at any time you want to view an ad, simply hover over it with your cursor.</Text>}
    ];
    return (
      <div className='faq-wrapper'>
        {faq.map((item, index) => {
          return (
            <OneQandA key={ index } activeFaq={ activeFaq } index={ index } item={item} isRtl={this.state.isRightToLeft} />
          )
        })}
      </div>
    )
  }
  render() {
    let definition = this.state.definition;
    let videoUrl = "/videos/lp_install_video_chrome.mp4?id=5.0";
    if (this.state.browserName === "Microsoft Edge") {
      videoUrl = "/videos/lp_install_video_edge.mp4?id=5.0";
    }
    
    let safeSearchPlaceholder = (definition.newSearch && definition.newSearch.safeSearchPlaceholder) ? definition.newSearch.safeSearchPlaceholder : "";
    //set the title as well here
    if(!this.state.query) {
      document.title = safeSearchPlaceholder;
    }
    let isExtensionInstalled = (DetectBrowser.isBrowserSupported(this.state.browserName) === false) ? true : this.state.isExtensionInstalled; //hackish fix to treat unsupported browser as if extension is installed
    return (
      <IntlProvider definition={definition}>
      <div className='landing'>
        <Header />
        <div>
            <div className="page-title ae-headline"><Text id="landing.mainTitle">Protecting your PC isn’t enough. Upgrade to true online safety.</Text></div>
        </div>
        {this.renderBenefitsSection()}
        {this.renderOnlineSafetySection()}
        {this.renderBuiltBySection()}
        <div className='animation'>
        </div>
        <div className="title-margin ae-headline"><Text id="landing.faqTitle">Frequently Asked Questions</Text></div>
        {this.renderFAQ()}
        <div className="title-margin ae-headline"><Text id="landing.mainTitle">Protecting your PC isn’t enough. Upgrade to true online safety.</Text></div>
        <div className='download-buttons-wrap'>
          {this.benefits.map((item, i) => {
            return (
              <div className='download-button-footer'>
                {i == 0 ? this.renderDownloadButton(item.id, item.buttonText) : ""}
              </div>
            )
          })}
        </div>
        <Footer />
      </div>
      <div id="loadingDiv" style={{display: "none"}} className="ui segment">
        <div class="ui active inverted dimmer">
          <div class="ui large text loader" id="loadingDivText"></div>
        </div>        
      </div>
      </IntlProvider>
    )
  }
}

class OneQandA extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isActive: this.props.activeFaq === this.props.index
    }
  }
  togleFaq() {
		this.setState(c => {
			c.isActive = !this.state.isActive;
      return c;
		});
	}
  render() {
    const { item, isRtl } = this.props;

    let icon_class = isRtl ? 'faq-icon-rtl' : 'faq-icon';
    icon_class = this.state.isActive ? icon_class + ' rotate-180' : icon_class;
    return (
      <p>
        <div className={isRtl ? 'faq-question-rtl' : 'faq-question'} onClick={this.togleFaq.bind(this)}>
          <div>
            <img src="/images/circle_chevron_down_blue.svg" className={icon_class} alt="chevron down" />
          </div>
          <div className='ae-para-large-bold'>{item.question}</div>
        </div>
        <div className='ae-para-large faq-answer' style={this.state.isActive ? {display: "block"} : {display: "none"}}>
          {item.answer}
        </div>
      </p>
    )
  }
}

let redirect = false;
let isAd = false;
let affid = GetSetAffiliateId();
try {
  if(window.location.href.indexOf("sl=") > -1 || window.location.href.indexOf("utm_source=") > -1) {
    const slparams = new URLSearchParams(window.location.search);
    let saferlink = slparams.get('sl');
    if(saferlink) {
      redirect = true;
      let sluri = GetSaferShoppingUrl(saferlink, affid, "omnibar");
      window.location.href = sluri;
    }
    if(slparams.get('utm_source')) {
      redirect = true;
      window.location.href = `/lp${window.location.search}`;
    }
  }
} catch(err) {
  //console.log("redirecting err", err)
  redirect = false;
}
if(!redirect) {
  render(<Lp affid={affid} isAd={isAd}/>, document.body);
}

