import { h} from 'preact';
import { useState, useEffect  } from 'preact/hooks';
import { IntlProvider, Text } from 'preact-i18n';
import { TranslationUtil } from '../utils/TranslationUtil';

export const Footer = props => {
    const [definition, setDefinition] = useState({})
    let d = new Date();
    let currentYear = d.getFullYear(); 
    useEffect(() => {
        const translateLang = props.lang ? props.lang : TranslationUtil.GetSupportedLanguage(); // use props.lang if avaialable
        TranslationUtil.GetDefinition(translateLang, "footer").then((def) => {
            setDefinition(def);
        }).catch();
    }, []);

    return (
        <IntlProvider definition={definition}>
            <div className="page-footer content-footer">
                <div className="ae-caption page-footer-copyright">
                    <div>
                        <a href="/termsofuse" target="_blank"><Text id="footer.terms">Terms and Conditions</Text></a>&nbsp;|&nbsp; 
                        <a href="/privacypolicy" target="_blank"><Text id="footer.privacy">Privacy Statement</Text></a>&nbsp;|&nbsp;
                        <a href="/cookieusage" target="_blank">Cookies</a>&nbsp;|&nbsp;
                        <a href="/support/HowToUninstall" target="_blank"><Text id="footer.uninstall">Uninstall</Text></a>&nbsp;|&nbsp;
                        <a href="/support" target="_blank"><Text id="footer.support">Support</Text></a>&nbsp;|&nbsp;
                        <a href="/blog" target="_blank"><Text id="footer.blog">Blog</Text></a>&nbsp;|&nbsp;
                        <a href="https://customer.appesteem.com/polluters?ref=footer" target="_blank"><Text id="footer.polluters">Polluter Program</Text></a>
                    </div>
                    <div>
                        <span>Copyright&copy; 2019-{currentYear} AppEsteem Corporation</span>
                    </div>
                </div>
            </div>
        </IntlProvider>
    )
}
